import { ready } from "./ready";

ready(() => {
    if (castleEnabled()) {
        initTokenInjectForFormSubmit();
        initTokenInjectForJQueryAjax();
        initTokenInjectForRailsUjs();
    }
});

function castleEnabled() {
    return !!window.YNAB_CLIENT_CONSTANTS?.CASTLE_PUBLISHABLE_KEY && typeof window.Castle !== "undefined";
}
function initTokenInjectForFormSubmit() {
    $("form[data-castle-inject-token]").on("submit", function (event) {
        window.Castle.injectTokenOnSubmit(event);
    });
}
function initTokenInjectForJQueryAjax() {
    $(document).ajaxSend((_ev, xhr) => {
        setCastleRequestToken(xhr);
    });
}

function initTokenInjectForRailsUjs() {
    document.body.addEventListener("ajax:beforeSend", (event: CustomEvent) => {
        const xhr = event.detail[0];
        setCastleRequestToken(xhr);
    });
}

function setCastleRequestToken(xhr: XMLHttpRequest | JQuery.jqXHR<any>) {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    createCastleRequestToken().then((castleRequestToken) => {
        setXhrCastleRequestToken(xhr, castleRequestToken);
    });
}

// eslint-disable-next-line @typescript-eslint/promise-function-async
export function createCastleRequestToken(): Promise<string> {
    if (castleEnabled()) {
        return window.Castle.createRequestToken();
    }

    return Promise.resolve(null);
}

export function setXhrCastleRequestToken(xhr: XMLHttpRequest | JQuery.jqXHR<any>, castleRequestToken: string): void {
    if (castleEnabled()) {
        // Workaround for a race condition we've seen on Safari.
        // While we've only seen this in Safari, there are chances we see it elsewhere depending on browser implementations
        // as the code is fundamentally wrong.
        // We are setting the Castle headers right before a http request, but getting the token is an async operation,
        // which we can't await at this point.
        //
        // Workaround is to set the headers here AND right before the http request is made. We use a flag to ensure we don't
        // set the header twice as that would end up setting a comma separated string with two headers. We also check if
        // the request is in the correct state before setting the header.
        if (castleRequestToken && !xhr["__castleRequestTokenSet"] && xhr.readyState === 1) {
            xhr.setRequestHeader("X-Castle-Request-Token", castleRequestToken);
            xhr["__castleRequestTokenSet"] = true;
        }
    }
}
